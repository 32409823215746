import React, { Component } from 'react';
import Service from '../../services';
import { baseUrlAdmin } from '../../store/apiConstants';
import moment from 'moment';
import './styles.scss';
import { QuestionTypeKeys, QuestionTypeKeysWithAQ } from '../questionBank/constants';
class PaperDownload extends Component {

    constructor(props) {
        super(props);
        const { examId } = this.props.match.params;
        this.state = {
            examId,
            exam: null,
            examType: null

        }

        this.service = new Service();
    }


    callMathJax = () => {
        if (window && window.MathJax && window.MathJax.Hub) {

            window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub]);
        }

    }

    minToHours = (n) => {
        var num = n;
        var hours = (num / 60);
        var rhours = Math.floor(hours);
        var minutes = (hours - rhours) * 60;
        var rminutes = Math.round(minutes);
        if (rminutes == 0) {
            return rhours;
        } else {
            return rhours + ":" + rminutes;
        }

    }

    componentDidMount() {
        if (this.state.examId) {
            let url = `${baseUrlAdmin}/examination/${this.state.examId}/exam-type/dummytest/paper`;

            //  let url = `http://localhost:3000/v1/data/examination/${this.state.examId}/exam-type/dummytest`;
            this.service.get(url, true).then((res) => {
                if (res.status && res.res && res.res.Item) {
                    if(res.res?.Item[0]?.examType === "Assessment") {
                        let x = res.res.Item[0]
                        let arr = x.questions.map(e => e.subjectName)
                        arr = [...new Set(arr)]
                        let a = []
                        arr.map((b,j) => {
                            let subjectArr = x.questions.filter((c,ci) => c.subjectName === b)
                            a.push({subjectName : b,questions : [...subjectArr]})
                        })
                        x.assessments = a
                        this.setState({
                            exam: x,
                            examType: res.res.Item[0].examType
                        })
                    }else{
                        this.setState({
                            exam: res.res.Item[0],
                            examType: res.res.Item[0].examType
                        });
                    }
                }
            }).catch(e => {
            })
        }
    }

    componentDidUpdate() {
        this.callMathJax();
    }





    renderQuestion = (q, qIndex, questions) => {
        switch (q.questionType) {
            case QuestionTypeKeysWithAQ.SCCT:
            case QuestionTypeKeysWithAQ.MCCT:
                return <>
                    <p className='question'><span className='qNo'>{q.qNo}.</span> {q.question}</p>
                    {
                        q.attachmentUrl && q.attachmentUrl.length > 2 && <>
                            <img src={'https://meluhaimage.s3.ap-south-1.amazonaws.com/' + q.attachmentUrl} className='q-attach' />
                        </>
                    }
                    <div className='sp-clearFix'></div>
                    {
                        q.options && q.options.length && q.options.map((a, oIndex) => {
                            return <div className={`option-w ${oIndex % 2 == 0 ? 'sp-clearFix' : ''}`} key={a.id}><p className='option'>{a.id}. {a.value} </p>
                                {
                                    a.imageUrl && a.imageUrl.length > 2 && <>
                                        <img src={'https://meluhaimage.s3.ap-south-1.amazonaws.com/' + a.imageUrl} className='o-attach' />
                                    </>
                                }
                            </div>
                        })
                    }
                </>
            case QuestionTypeKeysWithAQ.INT:
            case QuestionTypeKeysWithAQ.NUM:
            case QuestionTypeKeysWithAQ.FB:
                return <>
                    <p className='question'><span className='qNo'>{q.qNo}.</span> {q.question}</p>
                    {
                        q.attachmentUrl && q.attachmentUrl.length > 2 && <>
                            <img src={'https://meluhaimage.s3.ap-south-1.amazonaws.com/' + q.attachmentUrl} className='q-attach' />
                        </>
                    }
                    <div className='sp-clearFix'></div>
                    <p className='num-answer'></p>
                </>
            case QuestionTypeKeysWithAQ.PQS:
            case QuestionTypeKeysWithAQ.PQM:
                return <>
                    {(!qIndex) || !(qIndex && q.psgQId == questions[qIndex - 1].psgQId) && <>
                        <p className='question p-question'>{q.paragraph}</p>
                        {
                            q.paragraphUrl && q.paragraphUrl.length > 2 && <>
                                <img src={'https://meluhaimage.s3.ap-south-1.amazonaws.com/' + q.paragraphUrl} className='q-attach' />
                            </>
                        }
                    </>}
                    <div className='sp-clearFix'></div>
                    <p className='question'><span className='qNo'>{q.qNo}.</span> {q.question}</p>
                    {
                        q.attachmentUrl && q.attachmentUrl.length > 2 && <>
                            <img src={'https://meluhaimage.s3.ap-south-1.amazonaws.com/' + q.attachmentUrl} className='q-attach' />
                        </>
                    }
                    <div className='sp-clearFix'></div>
                    {
                        q.options && q.options.length && q.options.map((a, oIndex) => {
                            return <div className={`option-w ${oIndex % 2 == 0 ? 'sp-clearFix' : ''}`} key={a.id}><p className='option'>{a.id}. {a.value} </p>
                                {
                                    a.imageUrl && a.imageUrl.length > 2 && <>
                                        <img src={'https://meluhaimage.s3.ap-south-1.amazonaws.com/' + a.imageUrl} className='o-attach' />
                                    </>
                                }
                            </div>
                        })
                    }
                </>
            case QuestionTypeKeysWithAQ.VSAQ:
            case QuestionTypeKeysWithAQ.SAQ:
            case QuestionTypeKeysWithAQ.LAQ:
                return <>
                    <p className='question'><span className='qNo'>{q.qNo}.</span> {q.question}</p>
                    {
                        q.attachmentUrl && q.attachmentUrl.length > 2 && <>
                            <img src={'https://meluhaimage.s3.ap-south-1.amazonaws.com/' + q.attachmentUrl} className='q-attach' />
                        </>
                    }
                    <div className='sp-clearFix'></div>
                </>
            default:
                return <></>
        }
    }

    render() {
        const { exam, examType } = this.state;
        return <div className='e-paper'>
            {exam && <>
                <h1 className='school-title'>{exam.orgInfo?.title}</h1>
                <h4 className='school-address'>{exam.orgInfo?.city}, {exam.orgInfo?.state}</h4>
                <div className='e-header'>
                    <span className='e-date'>Time: {this.minToHours(parseInt(exam.totalTime))} Hours</span>
                    <div className='sp-right'>
                        <p className='e-date sp-no-pm'>Date: {moment(exam.fromDateTime).format("DD-MM-YYYY")}</p>
                        <p className='e-date  sp-no-pm' >Max. Marks {exam.totalMarks}</p>
                    </div>
                </div>
                <p className='e-exam-name'><span>{exam.examName} </span></p>
                <div className='ma-clearFix'>

                </div>

                <h5 className=' exam-instruction'>Exam Instructions</h5>
                <div className='sp-ml10 e-instructions' dangerouslySetInnerHTML={{ __html: exam.instructions }}>
                </div>
                <style>
                    {`
                                body {
                                    background-color:white !important;
                                }`}
                </style>
                {
                    exam && examType && (examType == 'AdhocMockTest' || examType == 'AdhocMockTestT2') && <>
                        {
                            exam.subjects.map((subject, ind) => {
                                return <div>
                                    <h4 className={`subject-name ${ind > 0 ? 'sp-mt40' : ''}`}>{subject.subjectName}</h4>
                                    <hr />
                                    <div className='paper-download' id="div">
                                        {
                                            subject.questions.map((q, qIndex) => {
                                                return <><div className='question-wrapper' key={q.questionId}>{this.renderQuestion(q, qIndex, subject.questions)}</div>
                                                    <div className='sp-clearFix'></div></>
                                            })
                                        }
                                    </div>
                                </div>
                            })
                        }
                    </>
                }

                {
                    exam && examType && examType == 'SubjectiveTest' && <>
                        {
                            exam.sections.map((section, ind) => {
                                return <div>
                                    <h4 className={`subject-name ${ind > 0 ? 'sp-mt40' : ''}`}>{section.sectionName}</h4>

                                    <h5 className=' exam-instruction'>Instructions</h5>
                                    <div className='sp-ml10 e-instructions' dangerouslySetInnerHTML={{ __html: section.instructions }}></div>

                                    <hr />
                                    <div className='paper-download' id="div">
                                        {
                                            section.questions.map((q, qIndex) => {
                                                return <><div className='question-wrapper' key={q.questionId}>{this.renderQuestion(q, qIndex, section.questions)}</div>
                                                    <div className='sp-clearFix'></div></>
                                            })
                                        }
                                    </div>
                                </div>
                            })
                        }
                    </>
                }
                {
                    exam && examType && examType == 'Assessment' && <>
                        {
                            exam.assessments.map((subject, ind) => {
                                return <div>
                                    <h4 className={`subject-name ${ind > 0 ? 'sp-mt40' : ''}`}>{subject.subjectName}</h4>
                                    <hr />
                                    <div className='paper-download' id="div">
                                        {
                                            subject.questions.map((q, qIndex) => {
                                                return <><div className='question-wrapper' key={q.questionId}>{this.renderQuestion(q, qIndex, subject.questions)}</div>
                                                    <div className='sp-clearFix'></div></>
                                            })
                                        }
                                    </div>
                                </div>
                            })
                        }
                </>
                }
            </>}
        </div>;
    }
}

export default PaperDownload